<template>
  <div class="row">
    <div class="col-7"/>
    <div class="col-5">
      <FormPreview/>
    </div>
  </div>
</template>

<script>
import FormPreview from 'common/components/form/FormPreview';

export default {
  name: 'IndexPage',
  components: {
    FormPreview,
  },
  data() {
    return {};
  },
};
</script>
